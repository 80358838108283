
/* general CSS */

.no-padding{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.no-underline {
  text-decoration: none;
}

p {
  font-size: 1.2rem;

}


/* CSS for the Navbar */
.nav-link {
  --bs-nav-link-color: #333;
  --bs-nav-link-disabled-color: #999;
  --bs-nav-link-disabled-hover-color: #999;
  --bs-nav-link-click-color: #333;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

:root {
  --linkedin-color: #0B65C2;
  --contact-color: #079CD9;
  --music-color: #FB5F84;
  --spotify-color: #1BD760;
  --youtube-color: #FF0000;
  --music-tabs-color: #333;
  --soundcloud-color: #FF0000;
  --projects-color: #FE5B35;
  --home-color: #FFCC01;
}

.music-tabs .nav-link:active, 
.music-tabs .nav-link:hover, 
.music-tabs .nav-tabs:active, 
.music-tabs .nav-tabs:hover {
  color: var(--music-tabs-color) !important;
}

.music-nav-link:hover,
.music-nav-link:focus {
  color: var(--music-color) !important;
}

.projects-nav-link:hover,
.projects-nav-link:focus {
  color: var(--projects-color) !important;
}

.home-nav-link:hover,
.home-nav-link:focus {
  color: var(--home-color) !important;
}

.contact-nav-link:hover,
.contact-nav-link:focus {
  color: var(--contact-color) !important;
}






/* CSS for the Projects section */

.card {
  border-radius: 0.75rem;
  border-width: 0rem;
}

/* for small screens */
@media only screen and (min-width: 576px) and (max-width: 767px){
  .card {
    min-height: 275px;
  }
}

/* for medium screens */
@media only screen and (min-width: 768px) and (max-width: 991px){
  .card {

    min-height: 275px;
  }
}

/* for large screens */
@media only screen and (min-width: 992px) {
  .card {
    min-height: 275px;
  }
}

.card-body {
  background-color: #DDDDDD;
  border-radius: 0.75rem;
  border-width: 0rem;
}

.card-button {
  border-radius: 0.5rem;
  border-width: 0rem;
  background-color: #666;

}

.card-button:hover, .card-button:focus {
  background-color: #444

}

.card-button:active {
  background-color: #333 !important;

}